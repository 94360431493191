<template>
  <div id="totalSessionWrapper">
    <template v-if="totalSessions">
      <div class="title totalTitle">{{ $t('total_sessions.title') | capitalize }}</div>
      <template v-for="(stat, index) in totalSessions">
        <div class="statContainer" :key="stat.id">
          <TotalSessionCard
            :logo="getStatIcon(index)"
            :text="getStatTitle(index)"
            :data="parseStat(stat,index, $i18n.locale, true)"
            :index="index"
          />
        </div>
      </template>
    </template>
    <LoadingDots v-else :black="true" class="loading" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { parseStat } from "../parsers";
import { icons } from "../constants";

const TotalSessionCard = () => import("./TotalSessionCard");
const LoadingDots = () => import("./LoadingDots");

export default {
  name: "TotalSessions",
  components: {
    TotalSessionCard,
    LoadingDots
  },
  computed: {
    ...mapState({
      totalSessions: state => state.totalSessions
    })
  },
  methods: {
    getStatTitle(stat) {
      return this.$t(`total_sessions.${stat}_label`);
    },
    getStatIcon(index) {
      const img = require.context("../assets/icons/", false, /\.png$/);
      return img("./" + icons[index] + ".png");
    },
    parseStat
  },
  created() {

    var value = value = {
    ...mapState({
        totalSessions: state => state.totalSessions
      })
    }
    console.log(value, "value");
  return value
  }
};
</script>

<style scoped>
#totalSessionWrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0 8%;
  justify-content: space-between;
}
.statContainer {
  flex: 1;
  margin: 2rem 0.3rem;
}
.loading {
  height: 100%;
  width: 100%;
  margin-top: 10%;
  font-size: 5rem !important;
}

.title {
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 0;
  align-self: center;
  width: 100%;
}
.totalTitle {
  margin: 6vh;
}

@media screen and (max-width: 1024px) {
  #totalSessionWrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 6%;
  }
  .statContainer {
    width: fit-content;
    flex: unset;
    margin: 2rem 0;
  }
}
</style>